<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('StopTimeProfile') }}</v-tab>
                            <v-tab :disabled="isLoading">{{ $t('Trips') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('Sequence')"
                                                    rules="required|numeric"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :label="$t('Sequence')"
                                                        filled
                                                        hide-details="auto"
                                                        name="sequence"
                                                        v-model="form.sequence"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('Headsign')" v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :label="$t('Headsign')"
                                                        filled
                                                        hide-details="auto"
                                                        name="headsign"
                                                        v-model="form.headsign"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('PickupType')" v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="pickupType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.pickup_type"
                                                        :label="$t('PickupType')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('DropOffType')" v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="dropOffType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.drop_off_type"
                                                        :label="$t('DropOffType')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('ShapeDistance')"
                                                    rules="numeric|min_value:0"
                                                    v-slot="{ errors }">
                                                    <v-text-field
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :label="$t('ShapeDistance')"
                                                        filled
                                                        hide-details="auto"
                                                        name="sequence"
                                                        v-model="form.shape_dist_traveled"
                                                        shaped></v-text-field>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('Timepoint')" v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="timepointType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.timepoint"
                                                        :label="$t('Timepoint')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('ContinuosPickup')" v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="continuosPickupType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.continuous_pickup"
                                                        :label="$t('ContinuosPickup')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider :name="$t('ContinuosDropOff')" v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="continuosDropOffType"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        v-model="form.continuous_drop_off"
                                                        hide-details
                                                        :label="$t('ContinuosDropOff')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('Stop')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-autocomplete
                                                        :error="errors[0] ? true : false"
                                                        hide-details="auto"
                                                        :search-input.sync="stopSearch"
                                                        @update:search-input="getStopsList"
                                                        v-model="form.stop"
                                                        item-value="id"
                                                        item-text="name"
                                                        :items="stops"
                                                        :disabled="isDisabled"
                                                        filled
                                                        shaped
                                                        name="stop"
                                                        :label="$t('Stop')"></v-autocomplete>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('ArrivalTime')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-menu
                                                        ref="menuArrival"
                                                        v-model="menuArrival"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="form.arrival_time"
                                                        transition="scale-transition"
                                                        offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.arrival_time"
                                                                :label="$t('ArrivalTime')"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                filled
                                                                hide-details="auto"
                                                                :disabled="isDisabled"
                                                                name="arrivalTime"
                                                                shaped></v-text-field>
                                                        </template>
                                                        <v-time-picker
                                                            v-if="menuArrival"
                                                            :error="errors[0] ? true : false"
                                                            :disabled="isDisabled"
                                                            format="24hr"
                                                            scrollable
                                                            v-model="form.arrival_time"
                                                            full-width
                                                            @click:minute="
                                                                $refs.menuArrival.save(form.arrival_time)
                                                            "></v-time-picker>
                                                    </v-menu>

                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('DepartureTime')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-menu
                                                        ref="menuDeparture"
                                                        v-model="menuDeparture"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="form.departure_time"
                                                        transition="scale-transition"
                                                        offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.departure_time"
                                                                :label="$t('DepartureTime')"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                filled
                                                                hide-details="auto"
                                                                name="departureTime"
                                                                :disabled="isDisabled"
                                                                shaped></v-text-field>
                                                        </template>
                                                        <v-time-picker
                                                            v-if="menuDeparture"
                                                            :error="errors[0] ? true : false"
                                                            :disabled="isDisabled"
                                                            format="24hr"
                                                            scrollable
                                                            v-model="form.departure_time"
                                                            full-width
                                                            @click:minute="
                                                                $refs.menuDeparture.save(form.departure_time)
                                                            "></v-time-picker>
                                                    </v-menu>

                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <div class="d-flex mt-8">
                                    <v-text-field
                                        expanded
                                        v-model="searchTripsQuery"
                                        @change="searchTrips()"
                                        :placeholder="$t('Search')"
                                        outlined
                                        hide-details
                                        dense
                                        :prepend-inner-icon="mdiMagnify"
                                        @keydown.enter.prevent="searchTrips"
                                        class="user-search me-3 mb-4">
                                    </v-text-field>
                                </div>
                                <ValidationProvider
                                    :name="$t('Trips')"
                                    rules="required"
                                    v-slot="{ errors }"
                                    ref="trips">
                                    <v-data-table
                                        show-select
                                        calculate-widths
                                        :items-per-page="5"
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 15, 20],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                                        :headers="tripsTableColumns"
                                        :items="form.trip.length > 0 ? orderBySelectedTrip : tripListTable"
                                        :server-items-length="totalTripListTable"
                                        v-model="form.trip"
                                        single-select
                                        name="trips"
                                        required
                                        :options.sync="tripOptions"
                                        @update:page="paginationHandle"
                                        height="500"
                                        fixed-header>
                                    </v-data-table>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="goBack" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addStopTime">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('StopTimeProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('StopTimeProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddStopTime') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddStopTime') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addStopTime">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteStopTime') }}
                                            <strong class="black--text">{{ form.headsign }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn
                                            @click="deleteStopTime"
                                            :loading="isLoading"
                                            color="error"
                                            class="px-5"
                                            >{{ $t('Buttons.Delete') }}</v-btn
                                        >
                                        <v-btn text @click="dialog.value = false" :loading="isLoading">{{
                                            $t('Buttons.Cancel')
                                        }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddStopTimeSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateStopTimeSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="deleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteStopTimeSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>

<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { getStops } from '@/api/stops';
    import useTripsList from '@/views/route/trips-list/useTripsList';

    const initialState = () => ({
        isLoading: false,
        form: {
            trip: [],
            arrival_time: '00:00',
            departure_time: '00:00',
            stop: '',
            sequence: null,
            headsign: '',
            pickup_type: null,
            drop_off_type: null,
            continuous_pickup: null,
            continuous_drop_off: null,
            shape_dist_traveled: null,
            timepoint: null,
        },
        fetchedStopName: '',
        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,

                addStopTime: false,

                isError: false,
                createSuccess: false,
                updateSuccess: false,
                deleteSuccess: false,
                menuArrival: false,
                menuDeparture: false,
                stopSearch: '',
                stops: [],
                debounce: null,
                searchTripsQuery: null,
                tripQuery: {},
                tripOptions: {
                    page: 1,
                },
                pickupType: [
                    { name: 'RegularPickup', value: 'O0' },
                    { name: 'NoPickupAvailable', value: 'O1' },
                    { name: 'PhoneAgencyPickup', value: 'O2' },
                    { name: 'PhoneDriverPickup', value: 'O3' },
                ],

                dropOffType: [
                    { name: 'RegularDropOff', value: 'O0' },
                    { name: 'NoDropOffAvailable', value: 'O1' },
                    { name: 'PhoneAgencyDropOff', value: 'O2' },
                    { name: 'PhoneDriverDropOff', value: 'O3' },
                ],

                continuosPickupType: [
                    { name: 'ContinuosPickup', value: 'O0' },
                    { name: 'NoContinuosPickup', value: 'O1' },
                    { name: 'PhoneAgencyPickup', value: 'O2' },
                    { name: 'PhoneDriverPickup', value: ' O3' },
                ],
                continuosDropOffType: [
                    { name: 'ContinuosDropOff', value: 'O0' },
                    { name: 'NoContinuosDropOff', value: 'O1' },
                    { name: 'PhoneAgencyDropOff', value: 'O2' },
                    { name: 'PhoneDriverDropOff', value: 'O3' },
                ],

                timepointType: [
                    { name: 'ApproximateTime', value: 'O0' },
                    { name: 'ExactTime', value: 'O1' },
                ],
            };
        },

        setup() {
            const { tripsTableColumns, tripListTable, totalTripListTable, fetchTripList } = useTripsList();
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,

                tripsTableColumns,
                tripListTable,
                totalTripListTable,
                fetchTripList,
            };
        },
        created() {
            this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
            this.fetchTripList();
            this.getStopsList();
            for (let index = 0; index < this.pickupType.length; index++) {
                this.pickupType[index].name = this.$t(this.pickupType[index].name);
            }

            for (let index = 0; index < this.dropOffType.length; index++) {
                this.dropOffType[index].name = this.$t(this.dropOffType[index].name);
            }
            for (let index = 0; index < this.continuosPickupType.length; index++) {
                this.continuosPickupType[index].name = this.$t(this.continuosPickupType[index].name);
            }
            for (let index = 0; index < this.continuosDropOffType.length; index++) {
                this.continuosDropOffType[index].name = this.$t(this.continuosDropOffType[index].name);
            }
            for (let index = 0; index < this.timepointType.length; index++) {
                this.timepointType[index].name = this.$t(this.timepointType[index].name);
            }

            if (this.$route.params.id != 'add') {
                this.addStopTime = false;
                this.isDisabled = true;
                this.getStopTime();
            } else {
                this.addStopTime = true;
                this.isDisabled = false;
            }
        },

        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            getStopsList() {
                if (this.fetchedStopName != '') {
                    this.stopSearch = this.fetchedStopName;
                }

                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    getStops({
                        'filters[$or]': [
                            {
                                name: {
                                    $containsi: this.stopSearch,
                                },
                            },
                        ],
                    }).then((response) => {
                        this.stops = response.data.data;

                        this.stops.map((stop) => {
                            if (stop.city) {
                                stop.name = stop.name + ' ' + `( ${stop.city} )`;
                            }
                        });
                        this.fetchedStopName = '';
                    });
                }, 500);
            },

            async getStopTime() {
                this.isLoading = true;
                await this.$store
                    .dispatch('app-routes/fetchStopTime', this.$route.params.id)
                    .then((response) => {
                        this.isLoading = false;

                        this.form = response.data.data;
                        this.form.trip = [response.data.data.trip];
                        this.fetchedStopName = this.form.stop.name;

                        this.getStopsList();
                        this.form.stop = response.data.data.stop.id;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async onSubmit() {
                if (this.addStopTime) {
                    await this.onAddTime();
                } else await this.onUpdateTime();

                setTimeout(() => {
                    this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 1 } });
                }, 1500);
            },

            async onAddTime() {
                this.isLoading = true;

                this.form.trip = this.form.trip.map((item) => {
                    return item.id;
                });

                await this.$store
                    .dispatch('app-routes/addStopTime', this.form)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            async onUpdateTime() {
                this.isLoading = true;

                this.form.trip = this.form.trip.map((item) => {
                    return item.id;
                });

                await this.$store
                    .dispatch('app-routes/updateStopTime', this.form)
                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            deleteStopTime() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/deleteStopTime', this.$route.params.id)
                    .then((response) => {
                        this.deleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 1 } });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },

            async getTransportList(search) {
                await getTransports({
                    sort: ['name:asc'],
                })
                    .then((response) => {
                        this.transports = response.data.data;
                    })
                    .catch((error) => {
                        throw error;
                    });
            },

            searchTrips() {
                this.isLoading = true;
                this.$store.commit('app-routes/setTripSearch', this.searchTripsQuery);
                this.fetchTripList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            paginationHandle(e) {
                if (this.tripOptions.page > this.tripQuery.pagination.page) {
                    this.tripQuery.pagination.page = this.tripOptions.page;
                    this.$store.commit('app-routes/setRoutesQuery', this.tripQuery);
                    this.fetchTripList();
                }
            },

            goBack() {
                if (this.$route.params.goToSchedule) {
                    this.$router.push({ name: 'SchedulesList' });
                } else this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 1 } });
            },
        },

        computed: {
            orderBySelectedTrip() {
                if (this.form.trip.length > 0 && this.form.trip[0] != null) {
                    this.tripListTable.unshift(this.form.trip[0]);

                    this.tripListTable = this.tripListTable.filter(
                        (route, index, self) => index === self.findIndex((i) => i.id === route.id),
                    );
                }

                return this.tripListTable;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
